import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queries } from '@/queries';
import { postOnboardingAccomplished } from '@/services/profile';

export const useOnboarding = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postOnboardingAccomplished,
    mutationKey: ['onboardingAccomplished'],
    onSuccess: () => {
      queryClient.invalidateQueries(queries.profile.me.queryKey);
    },
  });
};
