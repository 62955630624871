import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import type {
  ComponentProps,
  DetailedHTMLProps,
  ForwardedRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  WheelEvent,
} from 'react';
import { forwardRef } from 'react';

import ErrorAccordion from '@/components/ErrorAccordion';
import Text from '@/components/Text';

import TextInput from '../../TextInput';
import type { ErrorDetailsProps } from '../ArrayDataNestedForm';
import Label from './Label';

type Props = {
  label?: string | JSX.Element;
  description?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  prefixText?: string;
  prefixIcon?: IconDefinition;
  containerClassName?: string;
  labelClassName?: string;
  containerId?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  ComponentProps<typeof TextInput> &
  ErrorDetailsProps<string>;

const TextInputField = (
  {
    label,
    type,
    error,
    required = false,
    canHaveErrorMessage = true,
    description,
    containerClassName,
    labelClassName,
    variant,
    sizeVariant,
    containerId,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const onWheel = (e: WheelEvent<HTMLInputElement>) => {
    if (type === 'number') e.currentTarget.blur();
    props.onWheel?.(e);
  };
  return (
    <div id={containerId} className="flex flex-1 flex-col">
      <div
        className={classNames('flex flex-1 flex-col gap-4', containerClassName)}
      >
        {label && (
          <Label className={labelClassName} required={required}>
            {label}
          </Label>
        )}
        {description && (
          <Text as="span" className="text-gray-300">
            {description}
          </Text>
        )}
        <TextInput
          type={type}
          variant={variant ?? 'primary'}
          sizeVariant={sizeVariant}
          isError={!!error}
          {...props}
          ref={ref}
          onWheel={onWheel}
        />
      </div>
      {canHaveErrorMessage && <ErrorAccordion error={error} />}
    </div>
  );
};

export default forwardRef(TextInputField);
