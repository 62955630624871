/* eslint-disable react/jsx-no-useless-fragment */
import type { SelectProps } from '@mindpal-co/mindpal-ui';
import { Select as BaseSelect } from '@mindpal-co/mindpal-ui';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';

import type { LabelStyles } from './Form/Fields/Label';
import Label, { labelStyles } from './Form/Fields/Label';
import Text from './Text';

export type ControlStyleProps = VariantProps<ReturnType<typeof controlStyle>>;
const controlStyle = ({
  isFocused,
  isDisabled,
  error,
}: {
  isFocused: boolean;
  isDisabled: boolean;
  error: boolean;
}) =>
  cva(
    classNames('flex-1  p-2', {
      '!bg-neutral-900 border border-neutral-700': isDisabled && !error,
    }),
    {
      variants: {
        variant: {
          primary: classNames('rounded-lg text-white  border-2', {
            '!border-primary-500': isFocused,
            'border-error bg-error-20%': error,
            'border-transparent bg-primary-900': !error,
          }),
          gray: classNames('rounded-lg text-white border', {
            '!border-neutral-500': isFocused,
            'border-error bg-error-20%': error,
            'border-neutral-600 bg-neutral-1000': !error,
          }),
          secondary: classNames('rounded-lg text-white  border-2', {
            '!border-secondary-500': isFocused,
            'border-error bg-error-20%': error,
            'border-transparent bg-secondary-500/20': !error,
          }),
          light: classNames(
            '!bg-neutral-100 rounded-lg text-neutral-1000 border !border-neutral-1000',
            {
              '!border-primary-500': isFocused,
              'border-error bg-error-20%': error,
              'border-transparent bg-secondary-500/20': !error,
            }
          ),
          transparent: classNames('rounded-lg text-neutral-800 border', {
            '!border-primary-600': !isFocused,
            '!border-neutral-300': isFocused,
            'border-error bg-error-20%': error,
            'border-transparent bg-neutral-100': !error,
          }),
        },
        sizeVariant: {
          xs: 'px-2 py-0 text-body-small font-normal',
          sm: '!px-2 !py-0 text-body-small font-normal',
          base: 'p-2 text-body font-semibold',
        },
      },
      defaultVariants: {
        variant: 'primary',
        sizeVariant: 'base',
      },
    }
  );

type OptionStyleProps = VariantProps<ReturnType<typeof optionStyle>>;
const optionStyle = ({ isFocused }: { isFocused: boolean }) =>
  cva('my-1 px-3 py-2 text-neutral-200 ', {
    variants: {
      variant: {
        primary: classNames('rounded-lg', {
          'bg-primary-700': isFocused,
          'bg-primary-900': !isFocused,
        }),
        gray: classNames('rounded-lg', {
          'bg-neutral-700': isFocused,
          'bg-neutral-900': !isFocused,
        }),
        secondary: classNames('rounded-lg', {
          'bg-secondary-700': isFocused,
          'bg-secondary-900': !isFocused,
        }),
        light: classNames('', {
          'bg-primary-500 text-neutral-100': isFocused,
          'bg-neutral-0 !text-neutral-1000': !isFocused,
        }),
        transparent: classNames('rounded-lg', {
          'bg-primary-500': !isFocused,
          'bg-primary-700': isFocused,
        }),
      },
      sizeVariant: {
        xs: '!text-body-small font-normal',
        sm: '!my-0 !px-4 !text-body-small font-normal',
        base: 'text-body font-semibold',
      },
    },
    defaultVariants: {
      variant: 'primary',
      sizeVariant: 'base',
    },
  });
type MenuStyleProps = VariantProps<typeof menuStyle>;
const menuStyle = cva('!z-20 !w-max min-w-full max-w-xs rounded-lg  p-2 ', {
  variants: {
    variant: {
      primary: 'bg-primary-900 text-white',
      gray: 'bg-neutral-1000 text-white',
      secondary: 'bg-secondary-900 text-white',
      light: 'mt-2 overflow-hidden bg-neutral-0 !p-0 text-neutral-1000',
      transparent:
        'bg-neutral-100 text-neutral-800 !shadow-2xl shadow-neutral-800',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});
type MultiValueStyleProps = VariantProps<typeof menuStyle>;

const multiValueStyle = (isDisabled: boolean) =>
  cva('  border-2 p-2', {
    variants: {
      variant: {
        primary: classNames(
          'rounded-lg border-primary-500 bg-primary-700 text-white disabled:border-neutral-500 disabled:bg-neutral-700',
          {
            '!bg-neutral-800 !border-neutral-700': isDisabled,
          }
        ),
        gray: 'rounded-lg border-neutral-500 bg-neutral-700 text-white',
        secondary:
          'rounded-lg border-secondary-500 bg-secondary-700 text-white',
        light: 'rounded-lg border-neutral-1000 bg-neutral-0 text-neutral-1000',
        transparent: 'rounded-lg border-primary-500 bg-primary-700 !text-white',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  });

type SingleValueStyleProps = VariantProps<typeof singleValueStyle>;

const singleValueStyle = cva('', {
  variants: {
    variant: {
      primary: 'text-white',
      gray: 'text-primary-300',
      secondary: 'text-white',
      light: 'text-neutral-1000',
      transparent: 'text-neutral-800',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

interface SelectVariantProps
  extends MenuStyleProps,
    OptionStyleProps,
    ControlStyleProps,
    MultiValueStyleProps,
    SingleValueStyleProps {}

type Props = SelectVariantProps & {
  error?: string;
  labelClassName?: string;
  label?: string;
  description?: string;
  labelVariant?: LabelStyles;
};

export const Select = <
  T extends object & { isFixed?: boolean },
  IsMulti extends boolean = false,
  IsCreatable extends boolean = false,
  IsAsync extends boolean = false,
  IsAsyncCreatable extends boolean = false
>({
  variant,
  sizeVariant,
  error,
  label,
  labelClassName,
  required,
  description,
  isCreatable,
  isAsync,
  isAsyncCreatable,
  labelVariant,
  ...props
}: SelectProps<T, IsMulti, IsCreatable, IsAsync, IsAsyncCreatable> & Props) => {
  const isSecondaryLabel = variant === 'light';
  return (
    <BaseSelect
      {...props}
      isCreatable={isCreatable as true | undefined}
      isAsync={isAsync}
      isAsyncCreatable={isAsyncCreatable}
      classNames={{
        ...props.classNames,
        control: (classNameProps) =>
          classNames(
            controlStyle({
              isDisabled: classNameProps.isDisabled,
              isFocused: classNameProps.isFocused,
              error: !!error,
            })({
              variant,
              sizeVariant,
            }),
            props.classNames?.control?.(classNameProps)
          ),
        option: (classNameProps) =>
          classNames(
            optionStyle({ isFocused: classNameProps.isFocused })({
              variant,
              sizeVariant,
            }),
            props.classNames?.option?.(classNameProps)
          ),
        menu: () => classNames(menuStyle({ variant }), props.classNames?.menu),
        multiValue: (classNameProps) =>
          classNames(
            multiValueStyle(classNameProps.isDisabled)({ variant }),
            props.classNames?.multiValue?.(classNameProps)
          ),
        singleValue: (classNameProps) =>
          classNames(
            singleValueStyle({ variant }),
            props.classNames?.singleValue?.(classNameProps)
          ),
      }}
      labelElement={
        <>
          {label && (
            <Label
              required={required}
              className={classNames(
                labelClassName,
                labelStyles({
                  ...(isSecondaryLabel && { variant: 'secondary' }),
                  ...labelVariant,
                })
              )}
            >
              {label}
            </Label>
          )}
        </>
      }
      description={
        <>
          {description && (
            <Text as="span" className="text-gray-300">
              {description}
            </Text>
          )}
        </>
      }
    />
  );
};
